import { useEffect, useState } from 'react';
import { checkWindow, PAGES } from '@vfit/shared/data-access';
import { dehydrate } from 'react-query';
import { usePageProvider } from '@vfit/consumer/providers';
import { Layout } from '@vfit/consumer/components';
import { getStaticPathPages } from '@vfit/consumer/data-access';
import {
  CMS_CONFIG,
  getConfiguration,
  getStaticPathPagesDxl,
  IPageProps,
  IStaticPathPagesPaths,
} from '@vfit/business/data-access';
import { LayoutBusiness } from '@vfit/business/components';
// eslint-disable-next-line import/no-relative-packages
import prefetchAllQueries from '../../../libs/consumer/data-access/src/lib/cms/prefetchFS';
// eslint-disable-next-line import/no-relative-packages
import prefetchAllQueriesByType from '../../../libs/business/data-access/src/lib/cms/prefetchFSByType';

export default function PageBuilder({ page }: IPageProps) {
  const { setPage } = usePageProvider();
  const querystringParams = checkWindow() && window.location.search;
  const urlParams = new URLSearchParams(querystringParams);
  const isApp = urlParams.get('app');
 
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (page) {
      setPage(page);
    }
    setLoading(false);
  }, []);

  if(page?.params?.isVBIPages)
    return <LayoutBusiness page={page} isApp={!!isApp} />
  
  return !loading ? <Layout page={page} isApp={!!isApp} /> : <div />;
}

export async function getStaticPaths() {
  const allPages = await getStaticPathPages('business-homepage');
  const allPagesDxl = await getStaticPathPagesDxl();

  return {
    paths: [...allPages, ...allPagesDxl],
    fallback: false,
  };
}

export async function getStaticProps({ params }) {
  const contextPath = params?.path?.join('/') || '';
  const allPages = await getStaticPathPages('business-homepage');
  let pageObject: IStaticPathPagesPaths | null =
    allPages.find((page) => page.params?.path?.join('/') === contextPath) || null;
  let pageNavigation = await prefetchAllQueries(CMS_CONFIG[PAGES.BUSINESS_HOME_PAGE]);

  if (pageObject === null) {
    const allPagesDxl = await getStaticPathPagesDxl();
    pageObject = allPagesDxl.find((page) => page.params?.path?.join('/') === contextPath) || null;
    const config = getConfiguration(pageObject?.params?.layout, pageObject?.params?.pageType);
    pageNavigation = await prefetchAllQueriesByType(config);
  }

  return {
    props: {
      page: pageObject,
      dehydratedState: dehydrate(pageNavigation),
    },
  };
}

